import axios from '@/libs/axios'
import i18n from "@/libs/i18n";

const countryActions = {
    fetchCountries({commit, dispatch}, searchParams) {
        return axios.post('/countries/search', {params: searchParams})
    },
    fetchCountriesForSelect() {
        return new Promise((resolve, reject) => {
            axios.get('/countries/list', {params: {page: 1, limit: 10000}})
                .then(response => {
                    const list = []

                    if (response.data.entities.length) {
                        for (const key in response.data.entities) {
                            list.push({
                                value: parseInt(response.data.entities[key].id),
                                title: response.data.entities[key].name
                            })
                        }
                    }

                    resolve(list)
                }).catch(error => {
                reject(error)
            })
        })
    },
    fetchCountry({commit, dispatch}, id) {
        return axios.get(`/countries/${id}`)
    },
    storeCountry({commit, dispatch}, data) {
        return axios.post('/countries', data)
    },
    updateCountry({commit, dispatch}, data) {
        return axios.put(`/countries/${data.id}`, data)
    },
    deleteCountry({commit, dispatch}, id) {
        return axios.delete(`/countries/${id}`)
    },
}

export default countryActions
