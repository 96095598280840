import axios from '@/libs/axios'

const fileActions = {
    storeFile({commit, dispatch}, data) {
        return axios.post('/files/upload/file', data)
    },
    storeImage({commit, dispatch}, data) {
        return axios.post('/files/upload/image', data)
    },
    renameFile({commit, dispatch}, data) {
        return axios.post('/files/rename', data)
    },
    deleteFile({commit, dispatch}, data) {
        return axios.post('/files/delete', data)
    },
    downloadFile({commit, dispatch}, id) {
        return axios.get(`/files/download/${id}`)
    },
}

export default fileActions
