import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const toastHelper = {
  showSuccessToast(msg = '', title = '') {
    window.Vue.$toast({
      component: ToastificationContent,
      position: 'top-right',
      props: {
        title: !title.length ? window.i18n.t('modal.title.success') : title,
        icon: 'CoffeeIcon',
        variant: 'success',
        text: msg,
      },
    })
  },
  showSyncToast(msg = '', title = '') {
    window.Vue.$toast({
      component: ToastificationContent,
      position: 'top-right',
      props: {
        title: !title.length ? window.i18n.t('modal.title.sync') : title,
        icon: 'RefreshCcwIcon',
        variant: 'success',
        text: msg,
      },
    })
  },
  showErrorToast(msg = '', title = '') {
    window.Vue.$toast({
      component: ToastificationContent,
      position: 'top-right',
      props: {
        title: !title.length ? window.i18n.t('modal.title.error') : title,
        icon: 'AlertCircleIcon',
        variant: 'danger',
        text: msg,
      },
    })
  },
  showWarningToast(msg = '', title = '') {
    window.Vue.$toast({
      component: ToastificationContent,
      position: 'top-right',
      props: {
        title: !title.length ? window.i18n.t('modal.title.error') : title,
        icon: 'AlertCircleIcon',
        variant: 'warning',
        text: msg,
      },
    })
  },
  showResponseErrorToast(response) {
    let useErrorToast = true
    let msg = `${response.status} - ${response.statusText}`

    if (response.status === 500 && response.data.message !== undefined && response.data.message.length) {
      useErrorToast = false
      msg = response.data.message
    }

    // eslint-disable-next-line no-unused-expressions
    useErrorToast ? this.showErrorToast(msg) : this.showWarningToast(msg)
  },
}

export default toastHelper
