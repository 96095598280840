export default [
  {
    path: '/countries',
    name: 'countries',
    component: () => import('@/views/pages/country/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.countries',
      resource: 'country',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.countries',
          active: true,
        },
      ],
    },
  },
  {
    path: '/countries/add',
    name: 'add-country',
    component: () => import('@/views/pages/country/Add.vue'),
    meta: {
      pageTitle: 'breadcrumb.countries',
      resource: 'country',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.countries',
          active: false,
          to: {name: 'countries'}
        },
        {
          text: 'breadcrumb.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/countries/modify/:id',
    name: 'modify-country',
    component: () => import('@/views/pages/country/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.countries',
      resource: 'country',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.countries',
          active: false,
          to: {name: 'countries'}
        },
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
]
