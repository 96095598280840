import axios from '@/libs/axios'
import i18n from "@/libs/i18n";

const optionActions = {
    fetchOptions({commit, dispatch}, searchParams) {
        return axios.post('/options/search', {params: searchParams})
    },
    fetchOptionsForSelect() {
        return new Promise((resolve, reject) => {
            axios.get('/options/list', {params: {page: 1, limit: 10000}})
                .then(response => {
                    const list = []

                    if (response.data.entities.data.length) {
                        for (const key in response.data.entities.data) {
                            list.push({
                                value: parseInt(response.data.entities.data[key].id),
                                title: response.data.entities.data[key].name
                            })
                        }
                    }

                    resolve(list)
                }).catch(error => {
                reject(error)
            })
        })
    },
    fetchOption({commit, dispatch}, id) {
        return axios.get(`/options/${id}`)
    },
    updateOption({commit, dispatch}, data) {
        return axios.put(`/options/${data.id}`, data)
    },
}

export default optionActions
