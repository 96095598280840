import axios from '@/libs/axios'

const entityActions = {
    getSeoForEntity({commit, dispatch}, data) {
        return axios.get(`/entities/seo/${data.type}/${data.id}`)
    },
    updateSeoForEntity({commit, dispatch}, data) {
        return axios.post(`/entities/seo/${data.get('modelType')}/${data.get('modelId')}`, data)
    },
}

export default entityActions
