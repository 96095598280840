const viewHelper = {
  getResolution() {
    return window.innerWidth
  },
  // xs
  isMobileResolution() {
    return this.getResolution() < 576
  },
  // sm
  isSmallResolution() {
    const resolution = this.getResolution()
    return resolution >= 576 && resolution < 768
  },
  // md
  isMediumResolution() {
    const resolution = this.getResolution()
    return resolution >= 768 && resolution < 992
  },
  // lg
  isLargeResolution() {
    const resolution = this.getResolution()
    return resolution >= 992 && resolution < 1200
  },
  // xl
  isExtraLargeResolution() {
    const resolution = this.getResolution()
    return resolution >= 1200
  },
  // tablet ?
  isTabletResolution() {
    return this.isMediumResolution() || this.isLargeResolution()
  },
  // tablet ?
  isHDLaptopResolution() {
    const resolution = this.getResolution()
    return resolution >= 1200 && resolution < 1920
  },
  // eslint-disable-next-line consistent-return
  optimizeTableActionColumn(columnsDefinition, columnName, value) {
    const resolution = this.getResolution()
    const validResolution = resolution >= 768 && resolution < 1920

    if (!validResolution) {
      return columnsDefinition
    }

    // eslint-disable-next-line func-names
    columnsDefinition.forEach((part, index) => {
      if (part.field === columnName) {
        // eslint-disable-next-line no-param-reassign
        columnsDefinition[index].width = value
      }
    }, columnsDefinition)

    return columnsDefinition
  },
}

export default viewHelper
