export default [
  {
    path: '/options',
    name: 'options',
    component: () => import('@/views/pages/option/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.options',
      resource: 'option',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.options',
          active: true,
        },
      ],
    },
  },
  {
    path: '/options/modify/:id',
    name: 'modify-option',
    component: () => import('@/views/pages/option/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.options',
      resource: 'option',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.options',
          active: false,
          to: {name: 'options'}
        },
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
]
