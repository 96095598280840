import axios from '@/libs/axios'
import i18n from "@/libs/i18n";

const phoneNumberActions = {
    fetchPhoneNumbers({commit, dispatch}, searchParams) {
        return axios.post('/phone-numbers/search', {params: searchParams})
    },
    fetchPhoneNumbersForSelect({commit, dispatch}, searchParams) {
        return new Promise((resolve, reject) => {
            axios.get('/phone-numbers/list', {params: {page: 1, limit: 10000}})
                .then(response => {
                    const list = []

                    if (response.data.entities.length) {
                        for (const key in response.data.entities) {
                            list.push({
                                value: parseInt(response.data.entities[key].id),
                                title: response.data.entities[key].name
                            })
                        }
                    }

                    resolve(list)
                }).catch(error => {
                reject(error)
            })
        })
    },
    fetchPhoneNumber({commit, dispatch}, id) {
        return axios.get(`/phone-numbers/${id}`)
    },
    storePhoneNumber({commit, dispatch}, data) {
        return axios.post('/phone-numbers', data)
    },
    updatePhoneNumber({commit, dispatch}, data) {
        data.append('_method', 'put')
        return axios.post(`/phone-numbers/`+data.get('id'), data)
    },
    deletePhoneNumber({commit, dispatch}, id) {
        return axios.delete(`/phone-numbers/${id}`)
    },
}

export default phoneNumberActions
