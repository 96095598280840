import axios from '@/libs/axios'

const pageActions = {
    fetchPages({ commit, dispatch }, searchParams) {
        return axios.post('/pages/search', { params: searchParams })
    },
    fetchPage({ commit, dispatch }, id) {
        return axios.get(`/pages/${id}`)
    },
    storePage({ commit, dispatch }, data) {
        return axios.post('/pages', data)
    },
    updatePage({ commit, dispatch }, data) {
        return axios.put(`/pages/${data.id}`, data)
    },
    deletePage({ commit, dispatch }, id) {
        return axios.delete(`/pages/${id}`)
    },
}

export default pageActions
