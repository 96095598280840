import Vue from 'vue'

// axios
import axios from 'axios'

const token = `tt:beexoe6aichaGies`;
const encodedToken = Buffer.from(token).toString('base64');

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
    withCredentials: true,
    // crossDomains: true,
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Basic '+ encodedToken,
    },
})

Vue.prototype.$http = axiosIns

export default axiosIns
