export default [
  {
    path: '/area-codes',
    name: 'areaCodes',
    component: () => import('@/views/pages/areaCode/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.areaCodes',
      resource: 'areaCode',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.areaCodes',
          active: true,
        },
      ],
    },
  },
  {
    path: '/area-codes/add',
    name: 'add-areaCode',
    component: () => import('@/views/pages/areaCode/Add.vue'),
    meta: {
      pageTitle: 'breadcrumb.areaCodes',
      resource: 'areaCode',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.areaCodes',
          active: false,
          to: {name: 'areaCodes'}
        },
        {
          text: 'breadcrumb.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/area-codes/modify/:id',
    name: 'modify-areaCode',
    component: () => import('@/views/pages/areaCode/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.areaCodes',
      resource: 'areaCode',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.areaCodes',
          active: false,
          to: {name: 'areaCodes'}
        },
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
]
