import axios from '@/libs/axios'
import storageHelper from '@/libs/storage'

const appActions = {
  initApp({ commit, dispatch }) {
    const userToken = storageHelper.get('token')
    if (userToken) {
      commit('SET_TOKEN', userToken)
    }

    const userInfo = storageHelper.get('userData')
    if (userInfo) {
      const userData = JSON.parse(userInfo)
      commit('SET_USER_DATA', userData)
    }

    const locale = localStorage.getItem('locale')
    if (!locale) {
      localStorage.setItem('locale', 'hu')
    }
  },
}

export default appActions
