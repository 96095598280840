export default {
  ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  slugify(text, separator = '-') {
    return text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, separator)
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, separator)
  },
  removeTags(str) {
    if ((str === null) || (str === ''))
      return false;
    else
      str = str.toString();

    return str.replace(/(<([^>]+)>)/ig, '');
  },
  excerpt(str) {
    if (str.length > 70) {
      str = str.substring(0, 40) + "...";
    }
    return str;
  }
}
