import axios from '@/libs/axios'
import i18n from "@/libs/i18n";

const countyActions = {
    fetchCounties({commit, dispatch}, searchParams) {
        return axios.post('/counties/search', {params: searchParams})
    },
    fetchCountiesForSelect({commit, dispatch}, searchParams) {
        return new Promise((resolve, reject) => {
            axios.get('/counties/list', {params: {page: 1, limit: 10000, country_id: searchParams.country_id}})
                .then(response => {
                    const list = []

                    if (response.data.entities.length) {
                        for (const key in response.data.entities) {
                            list.push({
                                value: parseInt(response.data.entities[key].id),
                                title: response.data.entities[key].name
                            })
                        }
                    }

                    resolve(list)
                }).catch(error => {
                reject(error)
            })
        })
    },
    fetchCounty({commit, dispatch}, id) {
        return axios.get(`/counties/${id}`)
    },
    storeCounty({commit, dispatch}, data) {
        return axios.post('/counties', data)
    },
    updateCounty({commit, dispatch}, data) {
        return axios.put(`/counties/${data.id}`, data)
    },
    deleteCounty({commit, dispatch}, id) {
        return axios.delete(`/counties/${id}`)
    },
}

export default countyActions
