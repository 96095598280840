import axios from '@/libs/axios'
import i18n from "@/libs/i18n";

const phoneNumberCommentActions = {
    fetchPhoneNumberComments({commit, dispatch}, searchParams) {
        return axios.post('/phone-number-comments/search', {params: searchParams})
    },
    fetchPhoneNumberCommentsForSelect({commit, dispatch}, searchParams) {
        return new Promise((resolve, reject) => {
            axios.get('/phone-number-comments/list', {params: {page: 1, limit: 10000, country_id: searchParams.country_id}})
                .then(response => {
                    const list = []

                    if (response.data.entities.length) {
                        for (const key in response.data.entities) {
                            list.push({
                                value: parseInt(response.data.entities[key].id),
                                title: response.data.entities[key].name
                            })
                        }
                    }

                    resolve(list)
                }).catch(error => {
                reject(error)
            })
        })
    },
    fetchPhoneNumberComment({commit, dispatch}, id) {
        return axios.get(`/phone-number-comments/${id}`)
    },
    updatePhoneNumberComment({commit, dispatch}, data) {
        return axios.put(`/phone-number-comments/${data.id}`, data)
    },
    deletePhoneNumberComment({commit, dispatch}, id) {
        return axios.delete(`/phone-number-comments/${id}`)
    },
    setBulkStatus({commit, dispatch}, data) {
        return axios.post('/phone-number-comments/bulk-status', data)
    },
    fetchPhoneNumberCommentStatuses() {
        return new Promise((resolve, reject) => {
            axios.get('/phone-number-comments/statuses', {params: {page: 1, limit: 10000}})
                .then(response => {
                    const list = []

                    if (response.data.values.length) {
                        for (const key in response.data.values) {
                            list.push({
                                value: response.data.values[key].value,
                                title: i18n.t('comments.statuses.'+response.data.values[key].title)
                            })
                        }
                    }

                    resolve(list)
                }).catch(error => {
                reject(error)
            })
        })
    },
}

export default phoneNumberCommentActions
