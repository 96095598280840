import axios from '@/libs/axios'
import i18n from "@/libs/i18n";

const areaCodeActions = {
    fetchAreaCodes({commit, dispatch}, searchParams) {
        return axios.post('/area-codes/search', {params: searchParams})
    },
    fetchAreaCodesForSelect({commit, dispatch}, searchParams) {
        return new Promise((resolve, reject) => {
            axios.get('/area-codes/list', {params: {page: 1, limit: 10000, country_id: searchParams.country_id, county_id: searchParams.county_id}})
                .then(response => {
                    const list = []

                    if (response.data.entities.length) {
                        for (const key in response.data.entities) {
                            list.push({
                                value: parseInt(response.data.entities[key].id),
                                title: response.data.entities[key].area_name+' ('+response.data.entities[key].area_code+')'
                            })
                        }
                    }

                    resolve(list)
                }).catch(error => {
                reject(error)
            })
        })
    },
    fetchAreaCode({commit, dispatch}, id) {
        return axios.get(`/area-codes/${id}`)
    },
    storeAreaCode({commit, dispatch}, data) {
        return axios.post('/area-codes', data)
    },
    updateAreaCode({commit, dispatch}, data) {
        return axios.put(`/area-codes/${data.id}`, data)
    },
    deleteAreaCode({commit, dispatch}, id) {
        return axios.delete(`/area-codes/${id}`)
    },
}

export default areaCodeActions
