import axios from '@/libs/axios'

const dashboardActions = {
  basicStatistics({commit, dispatch}, params) {
    return axios.post('/dashboard/basic-statistics', params)
  },
  waitingComments({commit, dispatch}, params) {
    return axios.post('/dashboard/waiting-comments', params)
  },
  pageViews({commit, dispatch}, params) {
    return axios.post('/dashboard/metrics/page-views', params)
  },
  searches({commit, dispatch}, params) {
    return axios.post('/dashboard/metrics/searches', params)
  },
  operatingSystems({commit, dispatch}, params) {
    return axios.post('/dashboard/metrics/operating-systems', params)
  },
  platforms({commit, dispatch}, params) {
    return axios.post('/dashboard/metrics/platforms', params)
  },
  agents({commit, dispatch}, params) {
    return axios.post('/dashboard/metrics/agents', params)
  },
  referers({commit, dispatch}, params) {
    return axios.post('/dashboard/metrics/referers', params)
  },
}

export default dashboardActions
