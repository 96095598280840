export default [
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/pages/user/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.users',
      resource: 'user',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.users',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/add',
    name: 'add-user',
    component: () => import('@/views/pages/user/Add.vue'),
    meta: {
      pageTitle: 'breadcrumb.users',
      resource: 'user',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.users',
          active: false,
          to: {name: 'users'}
        },
        {
          text: 'breadcrumb.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/modify/:id',
    name: 'modify-user',
    component: () => import('@/views/pages/user/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.users',
      resource: 'user',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.users',
          active: false,
          to: {name: 'users'}
        },
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
]
