export default {
    formatPriceText(value, currency) {

        if(currency === 'HUF'){
            currency = {}
            currency.decimals = 0
            currency.decimalMark = '.'
            currency.thousandsSeparator = ' '
            currency.signLocation = 'right'
            currency.space = true
            currency.sign = 'Ft'
        }

        let val = (value / 1).toFixed(currency.decimals).replace('.', currency.decimalMark)
        let price = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, currency.thousandsSeparator)
        let space = '';

        if(currency.space){
            space = ' '
        }

        if(currency.signLocation === 'left'){
            return currency.sign+space+price
        }else{
            return price+space+currency.sign
        }
    },
}
