import moment from "moment";

const dateHelper = {
  getCurrentYear(){
    return moment(new Date()).year()
  },
  getCurrentDate(format = "YYYY-MM-DD") {
    return moment().format(format)
  },
  getCurrentDatePlusXDays(day = -30, format = "YYYY-MM-DD") {
    return moment().add(day, 'days').format(format)
  },
  getVueFlatpickrValues(value, defaultFromValue = false, defaultToValue = false) {

    defaultFromValue = defaultFromValue ? defaultFromValue : this.getCurrentDatePlusXDays(-30)
    defaultToValue = defaultToValue ? defaultToValue : this.getCurrentDate()

    if (typeof value !== 'string') {
      return [
        defaultFromValue,
        defaultToValue
      ]
    }

    let arr = value.split(' to ')

    if (!arr[1]) {
      if (arr.length === 1) arr.push(arr[0])
      else arr.push(defaultToValue)
    }

    return arr
  }
}

export default dateHelper
