export default [
  {
    path: '/phone-number-comments',
    name: 'phoneNumberComments',
    component: () => import('@/views/pages/phoneNumberComment/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.phoneNumberComments',
      resource: 'phoneNumberComment',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.phoneNumberComments',
          active: true,
        },
      ],
    },
  },
  {
    path: '/phone-number-comments/modify/:id',
    name: 'modify-phoneNumberComment',
    component: () => import('@/views/pages/phoneNumberComment/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.phoneNumberComments',
      resource: 'phoneNumberComment',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.phoneNumberComments',
          active: false,
          to: {name: 'phoneNumberComments'}
        },
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
]
