export default [
  {
    path: '/categories',
    name: 'categories',
    component: () => import('@/views/pages/category/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.categories',
      resource: 'category',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.categories',
          active: true,
        },
      ],
    },
  },
  {
    path: '/categories/add',
    name: 'add-category',
    component: () => import('@/views/pages/category/Add.vue'),
    meta: {
      pageTitle: 'breadcrumb.categories',
      resource: 'category',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.categories',
          active: false,
          to: {name: 'categories'}
        },
        {
          text: 'breadcrumb.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/categories/modify/:id',
    name: 'modify-category',
    component: () => import('@/views/pages/category/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.categories',
      resource: 'category',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.categories',
          active: false,
          to: {name: 'categories'}
        },
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
]
