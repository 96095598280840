export default [
  {
    path: '/redirects',
    name: 'redirects',
    component: () => import('@/views/pages/redirect/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.redirects',
      resource: 'redirect',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.redirects',
          active: true,
        },
      ],
    },
  },
  {
    path: '/redirects/add',
    name: 'add-redirect',
    component: () => import('@/views/pages/redirect/Add.vue'),
    meta: {
      pageTitle: 'breadcrumb.redirects',
      resource: 'redirect',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.redirects',
          active: false,
          to: {name: 'redirects'}
        },
        {
          text: 'breadcrumb.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/redirects/modify/:id',
    name: 'modify-redirect',
    component: () => import('@/views/pages/redirect/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.redirects',
      resource: 'redirect',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.redirects',
          active: false,
          to: {name: 'redirects'}
        },
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
]
