export default [
  {
    path: '/posts',
    name: 'posts',
    component: () => import('@/views/pages/post/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.posts',
      resource: 'post',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.posts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/posts/add',
    name: 'add-post',
    component: () => import('@/views/pages/post/Add.vue'),
    meta: {
      pageTitle: 'breadcrumb.posts',
      resource: 'post',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.posts',
          active: false,
          to: {name: 'posts'}
        },
        {
          text: 'breadcrumb.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/posts/modify/:id',
    name: 'modify-post',
    component: () => import('@/views/pages/post/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.posts',
      resource: 'post',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.posts',
          active: false,
          to: {name: 'posts'}
        },
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
]
