import stringHelper from "@/libs/helper/stringHelper";

const getters = {
  user: state => state.user,
  userID: state => (state.user && state.user.id ? state.user.id : null),
  userName: state => (state.user && state.user.name ? state.user.name : 'Anonim'),
  userRole: state => (state.user && state.user.role ? stringHelper.ucFirst(state.user.role) : '-'),
  userPermissions: state => (state.user && state.user.permissions ? state.user.permissions : null),
  isLogged: state => !!state.user,
  token: state => state.token,
}

export default getters
