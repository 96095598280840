import axios from '@/libs/axios'

const redirectActions = {
    fetchRedirects({commit, dispatch}, searchParams) {
        return axios.post('/redirects/search', {params: searchParams})
    },
    fetchRedirect({commit, dispatch}, id) {
        return axios.get(`/redirects/${id}`)
    },
    storeRedirect({commit, dispatch}, data) {
        return axios.post('/redirects', data)
    },
    updateRedirect({commit, dispatch}, data) {
        return axios.put(`/redirects/${data.id}`, data)
    },
    deleteRedirect({commit, dispatch}, id) {
        return axios.delete(`/redirects/${id}`)
    },
    fetchRedirectTypes({}) {
        return axios.get('/redirects/types')
    },
}

export default redirectActions
