export default [
  {
    path: '/phone-numbers',
    name: 'phoneNumbers',
    component: () => import('@/views/pages/phoneNumber/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.phoneNumbers',
      resource: 'phoneNumber',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.phoneNumbers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/phone-numbers/add',
    name: 'add-phoneNumber',
    component: () => import('@/views/pages/phoneNumber/Add.vue'),
    meta: {
      pageTitle: 'breadcrumb.phoneNumbers',
      resource: 'phoneNumber',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.phoneNumbers',
          active: false,
          to: {name: 'phoneNumbers'}
        },
        {
          text: 'breadcrumb.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/phone-numbers/modify/:id',
    name: 'modify-phoneNumber',
    component: () => import('@/views/pages/phoneNumber/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.phoneNumbers',
      resource: 'phoneNumber',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.phoneNumbers',
          active: false,
          to: {name: 'phoneNumbers'}
        },
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
]
