import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
// Telefonszám tudakozó Admin
import state from './state'
import actions from './tt/actions'
import mutations from './tt/mutations'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  // Telefonszám tudakozó
  state,
  actions,
  mutations,
  getters,
  modules: {
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
})
