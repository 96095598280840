import axios from '@/libs/axios'

const tagActions = {
    fetchTags({commit, dispatch}, searchParams) {
        return axios.post('/blog/tags/search', {params: searchParams})
    },
    fetchTagsForSelect() {
        return new Promise((resolve, reject) => {
            axios.get('/blog/tags/list', {params: {page: 1, limit: 10000}})
                .then(response => {
                    const list = []

                    if (response.data.entities.data.length) {
                        for (const key in response.data.entities.data) {
                            list.push({
                                value: parseInt(response.data.entities.data[key].id),
                                title: response.data.entities.data[key].name
                            })
                        }
                    }

                    resolve(list)
                }).catch(error => {
                reject(error)
            })
        })
    },
    fetchTag({commit, dispatch}, id) {
        return axios.get(`/blog/tags/${id}`)
    },
    storeTag({commit, dispatch}, data) {
        return axios.post('/blog/tags', data)
    },
    updateTag({commit, dispatch}, data) {
        return axios.put(`/blog/tags/${data.id}`, data)
    },
    deleteTag({commit, dispatch}, id) {
        return axios.delete(`/blog/tags/${id}`)
    },
    fetchTagStatuses({}) {
        return axios.get('/blog/tags/statuses')
    },
}

export default tagActions
