export default [
  {
    path: '/coming-soon',
    name: 'misc-coming-soon',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/error',
    name: 'misc-error',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages',
    name: 'pages',
    component: () => import('@/views/pages/page/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.pages',
      resource: 'page',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.pages',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pages/add',
    name: 'add-page',
    component: () => import('@/views/pages/page/Add.vue'),
    meta: {
      pageTitle: 'breadcrumb.pages',
      resource: 'page',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.pages',
          active: false,
          to: {name: 'pages'}
        },
        {
          text: 'breadcrumb.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pages/modify/:id',
    name: 'modify-page',
    component: () => import('@/views/pages/page/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.pages',
      resource: 'page',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.pages',
          active: false,
          to: {name: 'pages'}
        },
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
]
